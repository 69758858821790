@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background-color: #ff5757;
  box-shadow: 0px 4.42184px 107.23px rgba(255, 87, 87, 0.51);
}

.btn:hover {
  /*background: linear-gradient(
    92.23deg,
    #FFDE59 21.43%,
    #ff5757 50.63%,
    #ff5757 100%,
    #ff5757 117.04%
  );*/
  background-color: #dc4b4b;
}

.gradient {
  background: linear-gradient(
    92.23deg,
    #ff5757 21.43%,
    #ff5757 50.63%,
    #FFDE59 100%,
    #FFDE59 117.04%
  );
}

.active {
  color: #fff;
  padding: 0.7rem;
  border-radius: 15%;
  background-color: #ff5757;
  border-radius: 9999px;
}

.text-accent {
  color:#ff5757;
  font-weight: 600;
}

@layer base {
  body {
    @apply font-primary text-lg leading-5 text-black;
  }
  .h1 {
    @apply font-primary font-bold leading-[0.8] text-[70px] uppercase mb-6;
  }
  .h2 {
    @apply font-tertiary text-[32px] mb-6 tracking-[10%] uppercase leading-9;
  }
  .h3 {
    @apply font-secondary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .h4 {
    @apply font-special font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-[20px];
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply  text-[#FF5757] font-bold font-primary hover:text-[#dc4b4b];
  }
  .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  }
  .section-close {
    @apply py-8 lg:py-36 flex items-center;
  }

}

.discord {
  animation: shake-on-repeat 4.72s ease infinite;
  transform-origin: 50% 50%;
}

.insta {
  animation: shake-on-repeat 4.9s ease infinite;
  transform-origin: 50% 50%;
}

.linkedin {
  animation: shake-on-repeat 5.1s ease infinite;
  transform-origin: 50% 50%;
}


.shakingImg:hover {

  animation: shake 0.5s ease infinite;
}


@keyframes shake-on-repeat {
  0% { transform:translate(0,0) }
 1.78571% { transform: translate(-1px, -2px) rotate(-1deg); }
 3.57143% { transform: translate(-3px, 0px) rotate(1deg); }
 5.35714% { transform: translate(3px, 2px) rotate(0deg); }
 7.14286% { transform: translate(1px, -1px) rotate(1deg); }
 8.92857% { transform: translate(-1px, 2px) rotate(-1deg); }
 10.71429% { transform:translate(0,0) }
 100% { transform:translate(0,0) }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


